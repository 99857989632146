import React, { Component } from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Masonry from "react-masonry-css"
import Footer from "../components/footer"

const breakpointColumnsObj = {
  default: 3,
  1100: 3,
  960: 2,
  767: 1,
  500: 1,
}

class Posts extends Component {
  render() {
    function FeaturedImage(image) {
      if (!image) {
        return null
      }
      console.log(image)
      return (
        <div>
          <Img
            fluid={image.localFile.childImageSharp.fluid}
            alt=""
            className="img-thumb grid-image"
          />
        </div>
      )
    }

    const data = this.props.data
    console.log(data)
    return (
      <>
        <Layout>
        <SEO title="Trabajos" />
          <section className="works bgGrey ofsTop margTop ofsBottom">
            <div className="works-holder clearfix">
            <div className="container">
              <h1>Posts</h1>
              <Masonry
                breakpointCols={breakpointColumnsObj}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column"
              >
                {data.allWordpressPost.edges.map(({ node }) => (
                  <div className="grid-container">
                    <Link to={node.slug}>
                      {FeaturedImage(node.featured_media)}
                      <div className="overlay">
                        <div className="text">{node.title}</div>
                      </div>
                    </Link>
                  </div>
                ))}
              </Masonry>
            </div>
            </div>
            
            
          </section>
          <Footer/>
        </Layout>
      </>
    )
  }
}

export default Posts

export const pageQuery = graphql`
  query {
    allWordpressPage {
      edges {
        node {
          id
          title
          excerpt
          slug
        }
      }
    }
    allWordpressPost {
      edges {
        node {
          title
          excerpt
          slug
          featured_media {
            source_url
            localFile {
              childImageSharp {
                sizes(maxWidth: 1800) {
                  ...GatsbyImageSharpSizes
                }
                fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid
                }
                resize(width: 400, jpegProgressive: true) {
                  src
                }
              }
            }
          }
        }
      }
    }
  }
`
